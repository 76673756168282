import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Dimmer, Loader, } from "semantic-ui-react";

// import PublicRoute from "./utils/PublicRoute"
import PrivateRoute from "./utils/PrivateRoute"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SellerWeb = React.lazy(() => import("./pages/seller-web"));
const LandingWeb = React.lazy(() => import("./pages/landing-web"));
const Registration = React.lazy(() => import("./pages/registration-step1"));
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const Home = React.lazy(() => import("./pages/home"));

const Finance = React.lazy(() => import("./pages/finance"));
const Order = React.lazy(() => import("./pages/order"));
const Seller_Rating = React.lazy(() => import("./pages/seller-rating"));
const Factory_Deals = React.lazy(() => import("./pages/factory-deal"));
const Return = React.lazy(() => import("./pages/return"));
const Product = React.lazy(() => import("./pages/product/product"));
const Add_Product = React.lazy(() => import("./pages/product/add-product"));
const Profile = React.lazy(() => import("./pages/profile"));

const About_Us = React.lazy(() => import("./pages/footer-pages/about-us"));
const Career = React.lazy(() => import("./pages/footer-pages/career"));
const Contact = React.lazy(() => import("./pages/footer-pages/contact"));
const Customer_Care = React.lazy(() => import("./pages/footer-pages/customer-care"));
const Team = React.lazy(() => import("./pages/footer-pages/team"));
const Return_Policy = React.lazy(() => import("./pages/footer-pages/return-policy"));
const Brand_Policy = React.lazy(() => import("./pages/footer-pages/brand-declaration-cum"));

const Privacy_Policy = React.lazy(() => import("./pages/footer-pages/privacy-policy"));
const Term = React.lazy(() => import("./pages/footer-pages/term"));
const TcsTds = React.lazy(() => import("./pages/footer-pages/tcs-tds"));
const Commercial = React.lazy(() => import("./pages/footer-pages/commercial"));


export const API_URL1 = () => {
  let _url = window.location.href
  let local = _url.search("localhost")
  let stage = _url.search("111")
  const STAGE_API_URL = "http://b2bsellerapi.bytesbrick.com:3004/api/v1"
  const LIVE_API_URL = "http://b2bsellerapi.bytesbrick.com:3004/api/v1"
  if (local > 0) {
    return LIVE_API_URL//STAGE_API_URL
  } else if (stage > 0) {
    return STAGE_API_URL
  } else
    return LIVE_API_URL
}
const App = () => {
  // Only for maintenance
  // useEffect(() => {
  //   window.localStorage.clear()
  // }, [])
  
  return (
    <Router>
      <Suspense fallback={<Dimmer active ><Loader size="huge">Loading...</Loader></Dimmer>}>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Switch>
            <Route exact path="/" component={LandingWeb} />

            <Route exact path="/about-us" component={About_Us} />
            <Route exact path="/career" component={Career} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/customer-care" component={Customer_Care} />
            <Route exact path="/privacy-policy" component={Privacy_Policy} />
            <Route exact path="/return-policy" component={Return_Policy} />
            <Route exact path="/declaration-cum-indemnity-bond" component={Brand_Policy} />
            <Route exact path="/terms-of-service" component={Term} />
            <Route exact path="/tcs-tds-policy" component={TcsTds} />
            <Route exact path="/commercials-policy" component={Commercial} />

            <PrivateRoute exact path="/registration" component={Registration} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/seller-web/" component={SellerWeb} />
            <PrivateRoute exact path="/finance" component={Finance} />
            <PrivateRoute exact path="/finance/:type" component={Finance} />

            <PrivateRoute exact path="/order" component={Order} />
            <PrivateRoute exact path="/order/:type" component={Order} />

            <PrivateRoute exact path="/seller-rating" component={Seller_Rating} />
            <PrivateRoute exact path="/factory-deal" component={Factory_Deals} />

            <PrivateRoute exact path="/return" component={Return} />
            <PrivateRoute exact path="/product" component={Product} />
            <PrivateRoute exact path="/product/:params" component={Product} />
            <PrivateRoute exact path="/add-product" component={Add_Product} />
            <PrivateRoute exact path="/edit-product/:id" component={Add_Product} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/profile/:tabname" component={Profile} />

            <Route exact path="/:otherpages" component={Home} />
          </Switch>
        </div>
      </Suspense>
    </Router>
  );
}
export default App;