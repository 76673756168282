// return the user data from the session storage
export const getUser = () => {
    // const userStr = sessionStorage.getItem('LoggedUser');
    let userStr = JSON.parse(localStorage.getItem("LoggedUser"))
    if (userStr) return userStr;
    else return null;
}

// return the token from the session storage
export const getToken = () => {
    // return sessionStorage.getItem('LoggedUser') || null;
    return JSON.parse(localStorage.getItem("LoggedUser")) || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
}